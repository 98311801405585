<template>
  <div v-if="user">
    <div class="mt-8 text-center text-h6" @click="isQrcode = !isQrcode">
      會員條碼
    </div>

    <div class="mt-4 text-center text-h6" v-if="isQrcode">
      <QrcodeVue :value="userID" :size="100"></QrcodeVue>
      {{ userID }}
    </div>
    <VueBarcode
      v-else
      class="mt-0 text-center"
      :width="1.4"
      :height="100"
      :value="userID"
    >
      {{ userID }}
    </VueBarcode>

    <!-- <VueBarcode
      v-else
      class="mt-0 text-center"
      :width="1.4"
      :height="100"
      :value="userID"
    >
      {{ userID }}
    </VueBarcode> -->
    <div class="mt-8 text-center text-h6">
      目前{{ mainUnit.text }} {{ this.user.balance || 0 }} {{ mainUnit.unit }}
    </div>
    <div class="mt-0 text-center text-h6" v-if="this.user.ticket">
      目前彩票 {{ this.user.ticket || 0 }} 張
    </div>
    <div class="text-center warning--text" @click="getUser()">重新讀取</div>
  </div>
</template>
<script>
import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import login from "@/mixins/login";
const ballStores = ["@748tzjfx", "@437pthff"];
const clawStore = ["@695yimoc"];

export default {
  name: "Barcode",
  mixins: [login],
  components: { VueBarcode, QrcodeVue },
  data: () => ({
    isQrcode: true,
  }),
  computed: {
    // isClawStoreMember() {
    //   return clawStore.includes(this.user.store);
    // },
    mainUnit() {
      switch (true) {
        case ballStores.includes(this.user.store):
          return { text: "鋼珠", unit: "顆" };
        default:
          return { text: "點數", unit: "點" };
      }
    },
  },
  watch: {
    user: {
      handler() {
        if (this.user && ballStores.includes(this.user.store)) {
          this.isQrcode = false;
          return;
        }
      },
      immediate: true,
    },
  },
};
</script>
